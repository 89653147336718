<template>
    <div class="task_all">
        <div class="filter">
            <div class="search">
                <div class="item">
                    <el-select size="medium" v-model="user_type" popper-class="global_select_popper" @change="search">
                        <el-option v-for="item in userType" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="item" style="margin-left: 20px;">
                    <el-select size="medium" v-model="task_status" popper-class="global_select_popper" @change="search">
                        <el-option v-for="item in taskStatus" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="add_btn">
                <taskDialog :enter="1" @refresh="taskList"/><!--v-if="$store.state.user.current_user.dignity==3" -->
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                <el-table-column label="序号" align="center">
                    <template slot-scope="scope">
                        <div class="serial_number">{{ scope.row.index }}</div>
                        <div class="task_status overdue" v-if="scope.row.no_report">
                            <div>逾期未上报</div>
                        </div>
                        <div class="task_status finished" v-if="scope.row.status==4">
                            <div>任务已完成</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="任务名称" align="center">
                    <template slot-scope="scope">
                        <taskDialog :enter="3" :task_title="scope.row.task_title" :task_info="scope.row"
                                    @refresh="taskList"/>
                    </template>
                </el-table-column>
                <el-table-column label="任务类别" align="center">
                    <template slot-scope="scope">
                        <span>{{ initTaskType(scope.row.task_type) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务负责人" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.fuze_uids">{{ scope.row.fuze_uids.map(item => item.dingding.nickname).join('、') || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务承办人" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.deal_uids">{{ scope.row.deal_uids.map(item => item.dingding.nickname).join('、') || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="任务起止时间" align="center" width="210px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.start_time&&scope.row.end_time">
                            {{ scope.row.start_time.slice(0, 10).replace(/-/g, '.') }}
                            -
                            {{ scope.row.end_time.slice(0, 10).replace(/-/g, '.') }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="show_round" label="上报频率" align="center"></el-table-column>
                <el-table-column label="任务评价" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.task_appraise&&(scope.row.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1||task_power.power.register)"
                              :style="{color:initTaskAppraise(scope.row.task_appraise).color}">{{ initTaskAppraise(scope.row.task_appraise).text }}</span>
                        <!--$store.state.user.current_user.dignity==2||$store.state.user.current_user.dignity==3-->
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="operation">
                            <el-popover placement="bottom" width="100" :open-delay="200" trigger="hover"
                                        :visible-arrow="false" popper-class="operation_popover"
                                        transition="el-zoom-in-top">
                                <ul>
                                    <li>
                                        <reportList :enter="1" :task_info="scope.row"></reportList>
                                    </li>
                                    <li>
                                        <addAppraise :enter="1" :task_info="scope.row"></addAppraise>
                                    </li>
                                    <li>
                                        <addRegister :enter="1" :task_info="scope.row"></addRegister>
                                    </li>
                                    <li>
                                        <taskDialog :enter="3" :task_info="scope.row" @refresh="taskList"/>
                                    </li>
                                    <li>
                                        <taskDialog :enter="2" :task_info="scope.row" @refresh="taskList"
                                                    v-if="task_power.power.look==1||scope.row.create_uid.user_id===$store.state.user.current_user.userid"/>
                                    </li>
                                    <li>
                                        <delTask :enter="1" :task_info="scope.row" @refresh="taskList(true)"
                                                 v-if="task_power.power.look==1||scope.row.create_uid.user_id===$store.state.user.current_user.userid"></delTask>
                                    </li>
                                </ul>
                                <img slot="reference" src="../../../assets/img/caozuo.png" alt="">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                               layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import taskDialog from '@c/task/task_dialog.vue';
import computedData from '@/mixins/computedData.js';
import reportList from '@c/task/report/report_list.vue';
import addAppraise from '@c/task/appraise/add_appraise.vue';
import addRegister from '@c/task/register/add_register.vue';
import delTask from '@c/task/delete/delete_task.vue';

export default {
    components: {taskDialog, reportList, addAppraise, addRegister, delTask},
    mixins: [computedData],
    data() {
        return {
            tableData: [],
            page: 1, pagesize: 15, total: 0,
            loading: false,
            userType: [
                {value: 0, label: '所有任务'},
                {value: 1, label: '我创建的'},
                {value: 2, label: '我负责的'},
                {value: 4, label: '我承办的'},
            ],
            user_type: 0,
            taskStatus: [
                {value: 1, label: '待办任务'},
                {value: 2, label: '已完成任务'},
            ],
            task_status: 1
        };
    },
    computed: {
        task_power() {
            return this.$store.state.user.current_user.task_power;
        }
    },
    created() {
        this.taskList();
    },
    methods: {
        taskList(refresh = false) {
            this.loading = !refresh;
            if (!refresh) this.tableData = [];
            let param = {
                page: this.page,
                pagesize: this.pagesize,
                user_id: this.$store.state.user.current_user.userid,
                task_status: this.task_status
            };
            if (this.user_type) param.user_type = this.user_type;
            api.task.taskList(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    this.tableData = res.data.lists || [];
                    if (this.tableData.length > 0 && res.data.user_ifo && res.data.user_ifo.length > 0) {
                        this.tableData.forEach((item, index) => {
                            item.create_uid = {};
                            item.fuze_uids = [];
                            item.deal_uids = [];
                            item.show_round = this.initShowRound(item.round) || '-';
                            item.index = ((this.page - 1) * this.pagesize + index) + 1;
                            item.no_report = false;//是否有逾期上报
                            res.data.user_ifo.forEach(user => {
                                if (user.task_id === item.task_id) {
                                    if (user.user_type == 1) {
                                        item.create_uid = user;
                                    }
                                    if (user.user_type == 2) {
                                        item.fuze_uids.push(user);
                                    }
                                    if (user.user_type == 4) {
                                        item.deal_uids.push(user);
                                    }
                                }
                            });
                            // if(res.data.no_report_task&&res.data.no_report_task.length>0){
                            //     res.data.no_report_task.forEach(no_report_task=>{
                            //         if(no_report_task.task_id===item.task_id){
                            //             item.no_report=true;
                            //         }
                            //     })
                            // }
                        });
                    }
                }
                this.loading = false;
            });
        },
        search() {
            this.page = 1;
            this.taskList();
        },
        pageChange(page) {
            this.page = page;
            this.taskList();
        },
    }
};
</script>

<style scoped lang="less">
.task_all {
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter {
        display: flex;
        padding: 10px 0;

        .search {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .add_btn {
            margin-left: 10px;
        }
    }

    .table {
        flex: 1;
        min-height: 0;

        /deep/ tbody {
            tr {
                td:first-of-type {
                    position: relative;
                    padding: 15px 0;
                    overflow: hidden;

                    .task_status {
                        position: absolute;
                        font-size: 12px;
                        padding: 0 20px;
                        line-height: 20px;
                        left: -28px;
                        top: 17px;
                        transform: rotate(-45deg);

                        div {
                            color: #fff;
                            transform: scale(0.75);
                        }

                        &.overdue {
                            background: #ff4848;
                        }

                        &.finished {
                            background: #4dc21e;
                        }
                    }
                }
            }
        }
    }
}
</style>